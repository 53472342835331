import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Intro from "../components/page-intro"
import Helmet from "react-helmet"
import Img from "gatsby-image"
import { motion } from 'framer-motion'


const duration = 0.25

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.08,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { x: 0, y: 20, opacity: 0 },
  visible: {
    y: 0,
    x: 0,
    opacity: 1,
  },
}

const Kind = () => {
  const data = useStaticQuery(graphql`
  query {
  allDirectory {
    edges {
      node {
        id
      }
    }
  }
  kindHomePage: file(relativePath: {eq: "kind-home-page.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindSectorPage: file(relativePath: {eq: "kind-sector-page.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindFooter: file(relativePath: {eq: "kind-footer.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindTopicPage: file(relativePath: {eq: "kind-topic-page.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindIllustrationDigital: file(relativePath: {eq: "kind-illustration-digital.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindIllustrationEnv: file(relativePath: {eq: "kind-illustration-environment.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindIllustrationProcess: file(relativePath: {eq: "kind-illustration-process.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindIllustrationInterns: file(relativePath: {eq: "kind-illustration-interns.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindIllustrationGlasto: file(relativePath: {eq: "kind-illustration-glastonbury.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindIllustrationBBC: file(relativePath: {eq: "kind-illustration-bbc.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindStory1: file(relativePath: {eq: "kind-story-1.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindStory2: file(relativePath: {eq: "kind-story-2.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindStory3: file(relativePath: {eq: "kind-story-3.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindMat: file(relativePath: {eq: "kind-mat.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindMichelle: file(relativePath: {eq: "kind-michelle.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindTom: file(relativePath: {eq: "kind-tom.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindSeth: file(relativePath: {eq: "kind-seth.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindMaria: file(relativePath: {eq: "kind-maria.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindBen: file(relativePath: {eq: "kind-ben.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindGallery: file(relativePath: {eq: "kind-gallery.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  kindSocial: file(relativePath: {eq: "social-kind.jpg"}) {
    childImageSharp {
      fixed(width: 1200, height: 630, quality: 100) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
}
`)
  return (
    <>
      <SEO 
        title="Kind Brand Design" 
        description="Brand refresh for a socially concious digital agency, Kind." 
        image={data.kindSocial.childImageSharp.fixed}
      />
      <Helmet
        htmlAttributes={{
          class: 'kind bg-white text-black',
        }}
      />

      <motion.section
        variants={container}
        initial="hidden" 
        animate="visible"
        className="container"
      >


        <Intro 
          borderColor="border-black border-opacity-10"
          introHeader={["Kind —"]}
          introSubHeader={["Brand refresh"]}
          introSummary="Working with a forward-thinking digital agency to develop a brand that supported their sustainable and socially-responsible outlook. A brand that was ultimately true to their name: Kind.">
        </Intro>
      
            <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">

              <div className="border-b"></div>

              <div className="border-b border-l px-6 md:px-10">

              <motion.div 
                variants={container}
                transition="easeInOut"
                className="intro-bottom flex lg:flex-wrap flex-row md:space-x-1 lg:space-x-1 items-center justify-center">
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="bg-kindNavy flex-grow h-full"></motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="bg-kindTeal flex-grow h-full"></motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="bg-kindMint flex-grow h-full"></motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="bg-kindLightMint flex-grow h-full"></motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="bg-kindRusset flex-grow h-full"></motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut" 
                  className="bg-kindCoralBeige flex-grow h-full"></motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="bg-kindYellow flex-grow h-full"></motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="bg-kindLightYellow flex-grow h-full"></motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="bg-black flex-grow h-full"></motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="bg-kindTextGrey flex-grow h-full"></motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="bg-kindClay flex-grow h-full"></motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="bg-kindBeige flex-grow h-full"></motion.div>
              </motion.div>

            </div>

            <div className="border-b border-l"></div>
          </div>

      </motion.section>


      <section className="content">

      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl ">
        <div className="border-b"></div>
        <div className="border-b border-l p-6 md:p-10 bg-bgKind">

          <div className=" w-full">
            <motion.div
              variants={container}
              initial="hidden" 
              animate="visible"
              className="container"
            >

              <div className="grid gap-4 grid-cols-12 md:gap-10 mb-10 md:py-12 xl:px-48 xl:py-32">
                <div className="col-start-1 col-span-12 md:col-span-6">
                  <h2>Developing the brand</h2>
                </div>
                <div className="col-span-12 md:col-start-7 md:col-span-5">
                  <p className="">Kind had a simple brand in place, however the only element that needed to stay was the logo, and with teal as the primary brand colour a much broader colour 
                  palette was developed to help support the overall tone of the brand, leaning into the various sectors Kind work within: Environment, Sustainability and Arts and Culture. </p>
                </div>
              </div>

              <figure>
                <Img backgroundColor="#f7e5b7" alt="Kind website homepage" fluid={data.kindHomePage.childImageSharp.fluid} className="w-full " />
                <figcaption className="m-4 text-xs text-tetxtGray">Kind : : Website home page design</figcaption>
              </figure>
              
            </motion.div>

            <div className="container">
              <div 
                className="md:grid grid-cols-12 gap-12 mt-12">
                  <div className="col-start-1 col-span-5">
                     <figure>
                      <Img backgroundColor="#d9f4e5" alt="Kind sector landing page"  fluid={data.kindSectorPage.childImageSharp.fluid} className="w-full" />
                      <figcaption className="m-4 text-xs text-tetxtGray">Kind : : Sector landing page design</figcaption>
                    </figure>
                  </div>
                  <div className="col-start-6 col-span-7">
                    <figure>
                      <Img backgroundColor="#a5debe" alt="Kind website footer" fluid={data.kindFooter.childImageSharp.fluid} className="w-full" />
                      <figcaption className="m-4 text-xs text-tetxtGray">Kind : : Sector landing page design</figcaption>
                    </figure>
                    <figure>
                      <Img backgroundColor="#2ba4b1" alt="Kind topic landing page"  fluid={data.kindTopicPage.childImageSharp.fluid} className="w-full  mt-12" />
                      <figcaption className="m-4 text-xs text-tetxtGray">Kind : : Sector landing page design</figcaption>
                    </figure>
                  </div>

              </div>
            </div>
          </div>

        </div>
        <div className="border-b border-l"></div>
      </div>



      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl ">
        <div className="border-b"></div>
        <div className="border-b border-l  p-6 md:p-10">


          <div className="grid gap-4 grid-cols-12 md:gap-10 md:py-12 xl:px-48 xl:py-32">
            <div className="col-start-1 col-span-12 md:col-span-6">
              <h2>Illustration</h2>
            </div>
            <div className="col-span-12 md:col-start-7 md:col-span-6 lg:col-span-5">
              <p className="">An illustrative style was developed in order to extend the visual language of the brand and communicate the various interests and values the company embody.</p>
            </div>
          </div>

          <div className="grid gap-4 grid-cols-12 md:gap-10 py-10">

            <div className="col-start-1 col-span-6 md:col-start-1 md:col-span-3">
              <Img backgroundColor="#ffc833" alt="Kind illustration - digital" className="md:-ml-6" fluid={data.kindIllustrationDigital.childImageSharp.fluid} />
            </div>

            <div className="col-start-7 col-span-6 md:col-start-4 md:col-span-3">
              <Img backgroundColor="#8F8B93" alt="Kind illustration - environment"  className="md:-ml-6" fluid={data.kindIllustrationEnv.childImageSharp.fluid} />
            </div>

            <div className="col-start-1 col-span-6 md:col-start-7 md:col-span-3">
              <Img backgroundColor="#e4a37b" alt="Kind illustration - process" className="md:-ml-6"  fluid={data.kindIllustrationProcess.childImageSharp.fluid} />
            </div>

            <div className="ccol-start-7 col-span-6 md:col-start-10 md:col-span-3">
              <Img backgroundColor="#003456" alt="Kind illustration - internships"  className="md:-ml-6"  fluid={data.kindIllustrationInterns.childImageSharp.fluid} />
            </div>

          </div>


          <div className="grid gap-4 grid-cols-12 md:gap-10 py-10 lg:py-20 md:pb-0 lg:pb-0">

            <div className="col-start-1 col-span-7 md:col-span-6 row-span-5">
              <figure>
                <Img backgroundColor="#2ba4b1" alt="Kind x Glastonbury illustration"  fluid={data.kindIllustrationGlasto.childImageSharp.fluid} />
                <figcaption className="m-4 text-xs text-tetxtGray">Kind x V&A - Glastonbury illustration</figcaption>
              </figure>       
            </div>

            <div className="col-star-1 row-start-12 col-span-12 md:col-start-6 md:col-span-6 md:col-start-7 md:col-span-5 row-span-5 md:row-start-1 lg:row-start-1 lg:col-start-7 lg:col-span-4">
              <p className="text-sm md:text-base">Illustration could be used as a communication tool when it came to updates and announcements, for example, creating illustrations for news relating to projects with V&A (Glastonbury) and the BBC.</p>
            </div>

            <div className="col-start-8 col-span-5 row-span-5 row-start-5 md:col-start-7 md:row-start-4">
              <figure>
                <Img backgroundColor="#a5debe" alt="Kind x BBC illustration"  fluid={data.kindIllustrationBBC.childImageSharp.fluid}  />
                <figcaption className="m-4 text-xs text-tetxtGray">Kind x BBC - Illustration</figcaption>
              </figure> 
              
            </div>

          </div>

        </div>
        <div className="border-b border-l"></div>
      </div>



      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">
        <div className="border-b"></div>
        <div className="border-b border-l">


          <div className="grid gap-0 grid-cols-12">
            <div className="row-start-2 lg:row-start-1 col-start-1 col-span-12 lg:col-span-9 p-6 md:p-10">
              <div className="grid gap-4 md:gap-10 grid-cols-12">
                  <div className="col-start-1 col-span-4">
                    <Img backgroundColor="#ffc833" alt="Kind social story example"  fluid={data.kindStory1.childImageSharp.fluid}  />
                  </div>
                  <div className="col-start-5 col-span-4">
                    <Img backgroundColor="#f7e5b7" alt="Kind social story example"  fluid={data.kindStory2.childImageSharp.fluid}  />
                  </div>
                  <div className="col-start-9 col-span-4">
                    <Img backgroundColor="#f1ebe8" alt="Kind social story example"  fluid={data.kindStory3.childImageSharp.fluid}  />
                  </div>
                </div>
              </div>
            <div className="row-start-1 lg:row-start-1 col-start-1 col-span-12 lg:col-start-10 lg:col-span-3 lg:border-l p-6 md:p-10">
              <h2>Social</h2>
              <p className="text-sm md:text-base">Along with an adaptable colour palette, organic patterns were created to bring a sense of depth and dynamism. Here they're applied across a series of soical templates for Instagram stories.</p>
            </div>
          </div>


          </div>
          <div className="border-b border-l"></div>
      </div>




       <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">
        <div className="border-b"></div>
        <div className="border-b border-l  p-6 md:p-10 ">

           <div className="grid gap-4 grid-cols-12 md:gap-10 md:py-12 xl:px-48 xl:py-32">
            <div className="col-start-1 col-span-12 md:col-span-6">
              <h2>Photography</h2>
            </div>
            <div className="col-start-1 col-span-12 md:col-start-7 md:col-span-6 lg:col-span-5">
              <p className="text-sm md:text-base">As well as branded team shots, photography was art-directed with the aim of capturing the personality of a friendly, approachable team of experts. With a relaxed office culture, photography needed to be natural and authentic with an underlying sense of fun.</p>
              <p className="text-sm md:text-base mt-4">Photography by <a href="https://faceiro.co.uk/" target="_blank" rel="noreferrer">Chris Underwood.</a></p>
            </div>
          </div>

      
          <div className="grid gap-4 grid-cols-12 md:gap-10 pb-10 py-10 lg:py-20 xl:px-48 ">
            <div className="col-start-1 col-span-4">
              <Img backgroundColor="#8F8B93" alt="Kind team photography - Mat"  fluid={data.kindMat.childImageSharp.fluid}  />
            </div>
            <div className="col-start-5 col-span-4">
              <Img backgroundColor="#a5debe" alt="Kind team photography - Michelle" fluid={data.kindMichelle.childImageSharp.fluid}  />
            </div>
            <div className="col-start-9 col-span-4">
              <Img backgroundColor="#f1ebe8" alt="Kind team photography - Tom"  fluid={data.kindTom.childImageSharp.fluid}  />
            </div>
            <div className="col-start-1 col-span-4">
              <Img backgroundColor="#ffc833" alt="Kind team photography - Seth"  fluid={data.kindSeth.childImageSharp.fluid}  />
            </div>
            <div className="col-start-5 col-span-4">
              <Img backgroundColor="#dbccc1" alt="Kind team photography - Maria" fluid={data.kindMaria.childImageSharp.fluid}  />
            </div>
            <div className="col-start-9 col-span-4">
              <Img backgroundColor="#8F8B93" alt="Kind team photography - Ben" fluid={data.kindBen.childImageSharp.fluid}  />
            </div>
            
          </div>
          <hr/>
          <div className="mt-10 lg:mt-20">
            <Img backgroundColor="#F1EBE8" alt="Kind team photography - gallery"  fluid={data.kindGallery.childImageSharp.fluid}  />
          </div>

        </div>
        <div className="border-b border-l"></div>
    </div>

  </section>
      
    </>
  )
}



export default Kind

